<template>
  <h1>Erste Schritte auf der Plattform</h1>

</template>

<script lang="ts">

</script>

<style>

</style>